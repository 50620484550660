<template>

	<a-card :bordered="false" class="widget-1">
		<!--a-statistic
			:title="title"
			:value="value"
			:prefix="prefix"
			:suffix="suffix"
			:precision="0"
			class="text-success"
			:class="'text-' + status"
		>
		</a-statistic-->
		<div class="ant-statistic-title">{{title}}</div>
		<div class="ant-statistic-content">
			<span class="ant-statistic-content-value"><router-link :to="link">{{value}}</router-link></span>
		</div>
		<div class="icon"><font-awesome-icon :icon="icon"/></div>
	</a-card>

</template>

<script>

	export default ({
		props: {
			title: {
				type: String,
				default: "",
			},
			value: {
				type: [String, Number],
				default: 0,
			},
			prefix: {
				type: String,
				default: "",
			},
			suffix: {
				type: String,
				default: "",
			},
			icon: {
				type: String,
				default: "",
			},
			status: {
				type: String,
				default: "success",
			},
			link: {
				tyle: String,
				default: "",
			},
		},
		data() {
			return {
			}
		},
	})

</script>