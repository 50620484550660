import request from '@/utils/request'

const dashboardApi = {
    get: '/api/dashboardinfo',
    getattacedproducts: '/api/underattack'
}

export function getdashboardinfo() {
    return request({
        url: dashboardApi.get,
        method: 'get'
    });
}

export function getattackedproduct() {
    return request({
        url: dashboardApi.getattacedproducts,
        method: 'get'
    });
}
