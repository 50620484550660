<template>
  <div>
    <canvas ref="chart" :style="{ height: height + 'px' }"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default {
  props: ["data","height"],
  watch: {
    data: function () {
      this.chart.destroy();
      this.renderChart();
    },
  },
  methods: {
    renderChart: function () {
      let ctx = this.$refs.chart.getContext("2d");

      this.chart = new Chart(ctx, {
        type: 'doughnut',
        data: this.data,
        options: {
            layout: {
						padding: {
							top: 40,
							right: 40,
							left: 40,
							bottom: 40,
						},
					},
            tooltips: {
						enabled: true,
						mode: "index",
						intersect: true,
					},
            plugins: {
						legend: {
              labels: {
                  color: 'rgb(255, 255, 255)'
              },
							display: true,
						},
					},
            responsive: true,
          
        },
      });
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.renderChart();
  },
  // Right before the component is destroyed,
  // also destroy the chart.
  beforeDestroy: function () {
    this.chart.destroy();
  },
};
</script>

<style lang="scss" scoped>
</style>