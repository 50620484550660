<template>

	<!-- Active Users Card -->
	<a-card :bordered="false" class="dashboard-bar-chart">
		<template #title>
     	 <h6>Top selling products</h6>
   		 <p>sales than last week <span class="text-success">+23%</span></p>
   		</template>
		<chart-bar :height="220" :data="barChartData"></chart-bar>
		
		<a-row class="card-footer" type="flex" justify="center" align="top">
			<a-col :span="6">
				<h4>1,6K</h4>
				<h6>Customers</h6>
			</a-col>
			<a-col :span="6">
				<h4>400</h4>
				<h6>Baskets</h6>
			</a-col>
			<a-col :span="6">
				<h4>70KSEK</h4>
				<h6>Total Sales</h6>
			</a-col>
			<a-col :span="6">
				<h4>5</h4>
				<h6>Items</h6>
			</a-col>
		</a-row>
	</a-card>
	<!-- Active Users Card -->

</template>

<script>

	// Bar chart for "Active Users" card.
	import ChartBar from '../Charts/ChartBar' ;

	export default ({
		components: {
			ChartBar,
		},
		data() {
			return {
				// Data for bar chart.
				barChartData: {
					labels: ["Vetemjöl", "Latte", "Citron", "Brownies", "Nötmix"],
					datasets: [{
						label: "Sales",
						backgroundColor: ['#9A89FF','#00D69E'],
						borderWidth: 0,
						borderSkipped: false,
						borderRadius: 6,
						data: [900, 850, 620, 600, 500],
						maxBarThickness: 20,
					}, ],
				},
			}
		},
	})

</script>

