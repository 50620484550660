<template>
  <a-card :bordered="false" class="dashboard-bar-line header-solid">
    <template #title>
      <h6>Goal fullfillment</h6>
      <p>goal fullfillment for products in this goal</p>
    </template>
    <chart-pie :data="pieChartData"></chart-pie>
  </a-card>
</template>

<script>
import ChartPie from '../Charts/ChartPie.vue';
// Bar chart for "Active Users" card.

export default {
  components: {
    ChartPie
  },
  data() {
    return {
      // Data for line chart.
      pieChartData: {
         labels: [
    'Goal achieves',
    'Goal fails'
  ],
       datasets: [{
    label: 'My First Dataset',
    data: [30,10],
    backgroundColor: [
      '#5FE5B8','#B8ADFF'
    ],
    hoverOffset: 4,
    cutout:'70%'
    
  }]
      },
    };
  },
};
</script>