<template>
<a-card :bordered="false" class="dashboard-bar-line header-solid">
    <template #title>
      <h6>Products under attack</h6>
      <p>Products under price attack from competitors</p>
    </template>
  <a-table :columns="columns" :data-source="data">
    <a slot="name" slot-scope="text, record"><router-link :to="{path:'/productdetail', query:{productid: record.productKey}}">{{ text }}</router-link></a>
    <!-- <span slot="customTitle"><a-icon type="smile-o" /> Name</span> -->
    <!-- <span slot="tags" slot-scope="tags">
      <a-tag
        v-for="tag in tags"
        :key="tag"
        
      >
        {{ tag.toUpperCase() }}
      </a-tag>
    </span> -->
  </a-table>
  </a-card>
</template>

<script>

import {getattackedproduct} from '@/api/dashboard';

const columns = [
  {
    title: 'Product name',
    dataIndex: 'productname',
    key: 'product',
    // slots: { title: 'customTitle'},
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Our price',
    dataIndex: 'ourprice',
    key: 'ourprice',
  },
  {
    title: 'Lowest price',
    dataIndex: 'minprice',
    key: 'minprice',
  },

  // {
  //   title: 'Categories',
  //   key: 'tags',
  //   dataIndex: 'tags',
  //   scopedSlots: { customRender: 'tags' },
  // },
  {
    title: 'Competitor',
    dataIndex: 'competitor',
    key: 'competitor',
  },
  {
    title: 'Checked time',
    dataIndex: 'timestamp',
    key: 'timestamp',
  },
];

const data = []

export default {
  data() {
    return {
      data,
      columns,
    };
  },
  mounted() {
    getattackedproduct().then(data=>{
      this.data = data
    })
  }

};
</script>
