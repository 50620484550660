<template>
  <div>
    <canvas ref="chart" :style="{ height: height + 'px' }"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default {
  props: [
			'data',
			'height',
		],
  watch: {
    data: function () {
      this.chart.destroy();
      this.renderChart();
    },
  },
  methods: {
    renderChart: function () {
      let ctx = this.$refs.chart.getContext("2d");

      this.chart = new Chart(ctx, {
        type: "radar",
        data: this.data,
        options: {

          scales: {
      r: {
        ticks: {
                    stepSize: 20,
                    textStrokeColor: 'rgb(255, 255, 255)',
                    color: 'rgba(255, 255, 255, 0.9)',
                    backdropColor: '#434343'
                },
                angleLines: {
                    color: 'rgba(245, 245, 245,0.9)',
                },

    
        
        grid: {
          color:  'rgba(255, 255, 255, 0.3)'
        },
        pointLabels: {
          font: {
            size: 20
          },
          color:'rgb(255, 255, 255)'
        }
      }
    },
            layout: {
						padding: {
							top: 40,
							right: 40,
							left: 40,
							bottom: 40,
						},
					},
            tooltips: {
						enabled: true,
						mode: "index",
						intersect: true,
					},
            plugins: {
						legend: {
                    labels: {
                  color: 'rgb(255, 255, 255)'
              },
							display: true,
						},
					},
            responsive: true,
          elements: {
            line: {
              borderWidth: 3,
            },
          },
        },
      });
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.renderChart();
  },
  // Right before the component is destroyed,
  // also destroy the chart.
  beforeDestroy: function () {
    this.chart.destroy();
  },
};
</script>

<style lang="scss" scoped>
</style>