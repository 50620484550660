<template>
  <a-card :bordered="false" class="dashboard-bar-line header-solid">
    <template #title>
      <h6>{{ graphtitle }}</h6>
      <p>Plan and real comparison</p>
    </template>
    <!-- <template #extra>
			<a-badge c5olor="primary" class="badge-dot-primary" text="Traffic" />
			<a-badge color="primary" class="badge-dot-secondary" text="Sales" />
		</template> -->
    <chart-radar :height="300" :data="radarChartData"></chart-radar>
  </a-card>
</template>

<script>
import ChartRadar from '../Charts/ChartRadar.vue';
// Bar chart for "Active Users" card.

export default {
  props: {
      graphtitle: String,
  },
  components: {
    ChartRadar
  },
  data() {
    return {
      // Data for line chart.
      radarChartData: {
        labels: [
          "Citron",
          "Brownies",
          "Nötmix",
          "Vetemjöl",
          "Latte",
        ],
        datasets: [
          {
            label: "Plan to sell",
            data: [80, 59, 80, 55, 56],
            fill: true,
            backgroundColor: "rgba(0, 215, 124, 0.2)",
            borderColor: "rgb(0, 215, 124)",
            pointBackgroundColor: "rgb(0, 215, 124)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(0, 215, 124)",
          },
          {
            label: "Real sales",
            data: [48, 48, 90, 63, 96],
            fill: true,
            backgroundColor: "rgba(154, 137, 255, 0.2)",
            borderColor: "rgb(154, 137, 255)",
            pointBackgroundColor: "rgb(154, 137, 255)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(154, 137, 255)",
          },
        ],
      },
    };
  },
};
</script>